<template>
  <div class="content-scrollable">
    <CommonSearchBarSafeArea />
    <CommonCollapse :initial-open="true" title="Info" class="mb-1">
      <CommonProperty name="Name">{{ name }}</CommonProperty>
      <CommonProperty name="Carrier">{{ carrier }}</CommonProperty>
      <CommonProperty name="Status">
        <VBadge v-if="status" :status="status" />
        <template v-else>–</template>
      </CommonProperty>
      <CommonProperty name="Start">{{ startAt }}</CommonProperty>
      <CommonProperty v-if="isOngoing" name="Estimated End">
        {{ estimatedEndAt }}
      </CommonProperty>
      <CommonProperty v-else name="Actual End">{{ endedAt }}</CommonProperty>
      <CommonProperty name="Scheduled End">{{ scheduledEndAt }}</CommonProperty>
      <CommonProperty name="Tracker">{{ deviceName }}</CommonProperty>
      <CommonProperty name="From">{{ fromAddress }}</CommonProperty>
      <CommonProperty name="To">{{ toAddress }}</CommonProperty>
      <CommonProperty name="Email">
        <div v-if="isArray(notificationEmails)">
          <div class="mb-1" v-for="email in notificationEmails" :key="email">
            <span class="rounded-md bg-gpx_gray-100 px-2 py-1 text-xs text-white">
              {{ email }}
            </span>
          </div>
        </div>
        <span v-else>–</span>
      </CommonProperty>
      <CommonProperty name="Link" v-if="uuid">
        <UIButtonNew
          size="sm"
          variant="destructive"
          class="!py-px !text-[10px]"
          @click="copyToClipboard"
        >
          Copy Link
        </UIButtonNew>
      </CommonProperty>
    </CommonCollapse>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useClipboard } from '@vueuse/core';
import CommonProperty from '@/components/_common/CommonProperty.vue';
import VBadge from '@/components/ui/VBadge.vue';
import { formatTime } from '@/filters';
import { nextTick } from 'vue';
import { setTitle } from '@/utils/title';
import { useToast } from 'vue-toastification';
import * as events from '@/events';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonCollapse from '@/components/_common/CommonCollapse.vue';
import UIButtonNew from '@/components/ui/UIButtonNew/UIButtonNew.vue';

const toast = useToast();

export default {
  components: {
    CommonProperty,
    VBadge,
    CommonSearchBarSafeArea,
    CommonCollapse,
    UIButtonNew,
  },

  data() {
    return {
      prevRoute: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      shipmentById: 'shipment/byId',
    }),
    id() {
      return +this.$route.params.id;
    },
    uuid() {
      return _.get(this.shipment, 'uuid', null);
    },
    uuidLink() {
      return `${window.location.origin}/shipments/track/${this.uuid}`;
    },
    shipment() {
      return this.shipmentById(+this.$route.params.id);
    },
    name() {
      return _.get(this.shipment, 'name', '-');
    },
    deviceName() {
      return _.get(this.shipment, 'device.name', '-');
    },
    carrier() {
      const carrier = _.get(this.shipment, 'carrier', null);
      return _.defaultTo(carrier, '-');
    },
    status() {
      return _.get(this.shipment, 'status', null);
    },
    startAt() {
      const startAt = _.get(this.shipment, 'start_at', null);
      if (startAt) {
        return formatTime(startAt, 'MMM d, yyyy h:mm aa');
      }
      return '-';
    },
    estimatedEndAt() {
      const endAt = _.get(this.shipment, 'estimated_end_at', null);
      if (endAt) {
        return formatTime(endAt, 'MMM d, yyyy h:mm aa');
      }
      return '-';
    },
    endedAt() {
      const endAt = _.get(this.shipment, 'ended_at', null);
      if (endAt) {
        return formatTime(endAt, 'MMM d, yyyy h:mm aa');
      }
      return '-';
    },
    scheduledEndAt() {
      const endAt = _.get(this.shipment, 'end_at', null);
      if (endAt) {
        return formatTime(endAt, 'MMM d, yyyy h:mm aa');
      }
      return '-';
    },
    trackerName() {
      return _.get(this.shipment, 'tracker_name', 'unknown');
    },
    fromAddress() {
      const id = _.get(this.shipment, 'from_geofence_id', null);
      if (id) {
        return _.get(this.shipment, 'from_geofence.address', '-');
      } else {
        return _.get(this.shipment, 'from_address.name', '-');
      }
    },
    toAddress() {
      const id = _.get(this.shipment, 'to_geofence_id', null);
      if (id) {
        return _.get(this.shipment, 'to_geofence.address', '-');
      } else {
        return _.get(this.shipment, 'to_address.name', '-');
      }
    },
    notificationEmails() {
      return _.get(this.shipment, 'notification_emails', null);
    },
    isOngoing() {
      const allowedStatuses = ['In Progress', 'Not Started', 'Running Late'];
      return allowedStatuses.includes(this.status);
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  created() {
    setTitle(`Shipment ${this.shipment.name}`);
  },

  mounted() {
    nextTick(() => {
      if (!this.prevRoute) {
        const id = parseInt(this.$route.params.id);
        this.fetchShipmentById({ id });
      }

      events.trigger(events.names.SHIPMENT_VIEWED, this.shipment);
    });
  },

  methods: {
    isArray: _.isArray,
    ...mapActions({
      fetch: 'shipment/fetchByAccountIdAndId',
    }),
    fetchShipmentById({ id }) {
      this.isLoading = true;
      this.fetch({
        accountId: this.accountId,
        id,
        params: {
          with_geofences: true,
          with_current_position: true,
          with_path: true,
        },
      }).finally(() => (this.isLoading = false));
    },
    copyToClipboard() {
      const { copy, copied } = useClipboard({ source: this.uuidLink });
      copy();
      if (copied) {
        toast.success('Copied!', { timeout: 800 });
      }
    },
    handleGoToEdit() {
      this.$router.push({ name: 'ShipmentEditView', params: { id: this.id } });
    },
  },
};
</script>
