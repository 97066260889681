import { VueQueryPlugin } from '@tanstack/vue-query'

import { setApi } from "./src/api";
import getUserDevices from "./src/getUserDevices";
import getAccountUser from "./src/getAccountUser";
import getAccountGroups from "./src/getAccountGroups";
import getAccountDevices from "./src/getAccountDevices";

const initializeQueries = (app, api) => {
    app.use(VueQueryPlugin)
    setApi(api);
}
export { initializeQueries, getUserDevices, getAccountUser, getAccountDevices, getAccountGroups }