<template>
  <AppHeader />
  <div class="relative z-[1] flex w-full flex-col sm:flex-row">
    <router-view name="bar" />
  </div>
  <main class="sm:flex">
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden sm:block sm:w-4/12"
      :class="[
        { '!block': mobileActiveTab === 'left' },
        {
          'pb-[86px] md:pb-[102px]': isBottomButton,
        },
      ]"
    >
      <router-view name="default" />
    </div>
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden sm:block sm:w-8/12"
      :class="[{ '!block': mobileActiveTab === 'right' }]"
    >
      <GeofenceMap v-if="geofencesActiveTab === 'geofences'" :view="view" />
      <BleAnchorMap v-if="geofencesActiveTab === 'bleAnchors'" :view="view" />
    </div>
    <MobileLayoutSwitch type="geofence" />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppHeader from '@/components/_common/AppHeader.vue';
import GeofenceMap from '@/components/geofence/map/GeofenceMap.vue';
import MobileLayoutSwitch from '@/components/_common/MobileLayoutSwitch.vue';
import BleAnchorMap from '@/components/geofence/map/BleAnchorMap.vue';
import { GEOFENCE_CATEGORY } from '@/config/constants';

export default {
  components: {
    GeofenceMap,
    AppHeader,
    MobileLayoutSwitch,
    BleAnchorMap,
  },

  data() {
    return {
      view: 'cluster',
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      mobileActiveTab: 'geofence/mobileActiveTab',
      geofencesActiveTab: 'geofence/activeTab',
      geofences: 'geofence/filtered',
      bleAnchors: 'geofence/filtered',
      isGeofencesBottomButton: 'geofence/isBottomButton',
      isBleTagsBottomButton: 'bleAnchor/isBottomButton',
    }),
    isBottomButton() {
      const isListView = this.$route.name === 'GeofenceListView';
      return (
        isListView &&
        (this.isGeofencesBottomButton || this.isBleTagsBottomButton)
      );
    },
  },

  watch: {
    '$route.name': {
      handler(n) {
        if (n === 'GeofenceListView') {
          this.setView('cluster');
        }
        if (n === 'GeofenceDetailsView') {
          this.setView('single');
        }
        if (n === 'GeofenceCreateView') {
          this.setView('create');
        }
        if (n === 'GeofenceEditView') {
          this.setView('edit');
        }
      },
    },
    accountId: {
      handler() {
        this.fetchGeofences();
      },
    },
  },

  beforeMount() {
    const view = this.$route.name;
    if (view === 'GeofenceListView') {
      this.setView('cluster');
    }
    if (view === 'GeofenceDetailsView') {
      this.setView('single');
    }
    if (view === 'GeofenceCreateView') {
      this.setView('create');
    }
    if (view === 'GeofenceEditView') {
      this.setView('edit');
    }
  },

  unmounted() {
    this.setActiveTab(GEOFENCE_CATEGORY.geofences);
  },

  methods: {
    ...mapActions({
      fetchGeofences: 'geofence/fetch',
      setActiveTab: 'geofence/setActiveTab',
    }),
    setView(type) {
      this.view = type;
    },
  },
};
</script>

<style></style>
