<script setup>
import RadioGroup from "../elements/RadioGroup.vue";
import RadioGroupOption from "../elements/RadioGroupOption.vue";
import DeviceAutocomplete from "./DeviceAutocomplete.vue";
import {getAccountDevices, getAccountGroups} from "@gpx-spa/queries";
import Spinner from "../elements/Spinner.vue";
import {ref, watch} from "vue";
import GroupsAutocomplete from "./GroupsAutocomplete.vue";

const role = defineModel("role", "all");
const deviceIds = defineModel("deviceIds");
const groupIds = defineModel("groupIds");
const fullDeviceAccess = defineModel("fullDeviceAccess", false);
const props = defineProps(['accountId'])
const accountId = ref(props.accountId)

watch(props, () => accountId.value = props.accountId);
watch(role, () => fullDeviceAccess.value = role.value !== 'devices')

const { data: accountDevices, isFetching: isFetchingDevices } = getAccountDevices(accountId);
const { data: accountGroups, isFetching: isFetchingGroups } = getAccountGroups(accountId);
</script>

<template>
  <div>
    <RadioGroup class="mt-[19px]">
      <RadioGroupOption
          v-model="role"
          :value="'admin'"
          :label="'Administrator'"
          :description="'Access to all devices, can invite users'"
      />
      <RadioGroupOption
          v-model="role"
          :value="'all'"
          :label="'All Devices'"
          :description="'Access to all devices, can not invite users'"
      />
      <RadioGroupOption
          v-model="role"
          :value="'viewer'"
          :label="'Viewer'"
          :description="'Read only access'"
      />
      <RadioGroupOption
          v-model="role"
          :value="'devices'"
          :label="'Specific Devices'"
          :description="'Access only specific devices'"
      />
    </RadioGroup>
    <div class="mt-[29px]" v-if="!fullDeviceAccess">
      <Spinner v-if="isFetchingDevices || isFetchingGroups" />
      <template v-else>
        <DeviceAutocomplete v-model="deviceIds" :devices="accountDevices" />
        <GroupsAutocomplete v-model="groupIds" :groups="accountGroups" class="mt-4"/>
      </template>
    </div>
  </div>
</template>