<template>
  <CommonSearchBarSafeArea />
  <div class="content-scrollable">
    <GeofenceForm :initial-values="initialValues" :is-b2b="isB2B" readonly>
    </GeofenceForm>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import GeofenceForm from '@/components/geofence/_common/GeofenceForm.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import { setTitle } from '@/utils/title';

onMounted(() => {
  setTitle(`Geofence ${geofence.value?.name}`);
});

const store = useStore();
const route = useRoute();

const isB2B = store.getters['auth/isB2B'];
const id = +route.params.id;
const geofence = computed(() => store.getters['geofence/byId'](id));

const initialValues = {
  ...geofence.value,
  address: {
    label: geofence.value.address,
    lat: 1,
    lng: 1,
  },
  is_alertable: Boolean(geofence.value.is_alertable),
};
</script>
