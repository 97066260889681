<template>
  <div
    class="relative grid h-[88px] w-[140px] grid-cols-2 grid-rows-2 rounded-[6px] px-[16px] pb-[11px] pt-[14px]"
    style="font-feature-settings: 'salt' on"
  >
    <img
      class="pointer-events-none absolute -z-10 h-full w-full select-none"
      :src="bgImage"
      :alt="computedType"
    />
    <div :class="{ 'pt-[2px]': computedType === 'visa' }">
      <img :src="brandImage" :alt="computedType" />
    </div>

    <div
      class="col-start-1 row-start-2 self-end text-gpx_sm tracking-[0.04em] text-white"
    >
      {{ valid }}
    </div>
    <div
      class="row-start-2 flex gap-x-[4px] self-end justify-self-end text-gpx_sm tracking-[0.04em] text-white"
    >
      <span>*</span><span>{{ digits }}</span>
    </div>
    <button
      class="absolute right-[4px] top-[2px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center"
      @click="handleDeleteCard"
    >
      <UISpinner v-if="deleting" size="sm" />
      <TrashIcon
        v-else
        class="heroicon-stroke-w-1.2 h-[20px] w-[20px] text-white"
      />
    </button>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import BgVisaCard from '@/assets/payment/bg-visa.png';
import BgMasterCard from '@/assets/payment/bg-mastercard.png';
import BgAmexCard from '@/assets/payment/bg-amex.png';
import BgDiscoverCard from '@/assets/payment/bg-discover.png';
import BrandVisaCard from '@/assets/payment/card-visa.svg';
import BrandMasterCard from '@/assets/payment/card-mastercard.svg';
import BrandAmexCard from '@/assets/payment/card-amex.svg';
import BrandDiscoverCard from '@/assets/payment/card-discover.svg';
import UISpinner from '@/components/ui/UISpinner.vue';

defineOptions({
  name: 'CreditCard',
});

// Props definition
const props = defineProps({
  cardId: {
    type: String,
    required: true,
  },
  type: {
    type: String, // Visa, Mastercard, AMEX
    required: true,
  },
  valid: {
    type: String,
    required: true,
  },
  digits: {
    type: String,
    required: true,
  },
});

// Events definition
const emit = defineEmits(['deleteCard']);

// State
const deleting = ref(false);

// Vuex store access
const store = useStore();
const accountId = computed(() => store.getters['auth/accountId']);

// Computed properties
const computedType = computed(() => props.type.toLowerCase());

const bgImage = computed(() => {
  const map = {
    visa: BgVisaCard,
    mastercard: BgMasterCard,
    amex: BgAmexCard,
    discover: BgDiscoverCard,
  };
  return map[computedType.value];
});

const brandImage = computed(() => {
  const map = {
    visa: BrandVisaCard,
    mastercard: BrandMasterCard,
    amex: BrandAmexCard,
    discover: BrandDiscoverCard,
  };
  return map[computedType.value];
});

// Methods
const toast = useToast();

const handleDeleteCard = async () => {
  deleting.value = true;
  
  try {
    await store.dispatch('paymentMethod/delete', {
      accountId: accountId.value,
      paymentMethodId: props.cardId,
    });
  } catch (err) {
    const message = err.response?.data?.message || 'Error deleting payment method';
    toast.error(message);
  } finally {
    deleting.value = false;
  }
};
</script>
