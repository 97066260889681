<template>
  <ModalLayout title="Download Invoice" full>
    <div class="rounded-t-[10px] bg-white px-[40px] pt-[41px] sm:px-[40px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex size-[40px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 size-[20px] text-gpx_gray-200"
        />
      </div>
      <div class="border-b border-b-gpx_gray-800 pb-[18px]">
        <div class="mb-[2px] text-sm font-medium text-gpx_gray-200">
          <span v-if="canPay">Invoice Payment for</span>
          <span v-else>Viewing Invoice for</span>
        </div>
        <div class="text-gpx_black-100 text-2xl font-semibold">
          Order <span v-if="order">#{{ orderId }}</span>
        </div>
        <div
          v-if="!canPay"
          class="mt-[17px] text-sm font-medium text-gpx_gray-200"
        >
          Status: <UIBadge :status="order.status" />
        </div>
      </div>
      <div class="mt-[35px] flex flex-col">
        <div class="flex justify-between">
          <img class="h-6 w-auto sm:h-12" :src="logo" alt="GPX" />
          <div class="flex flex-col items-end text-xs text-gpx_gray-300">
            <span class="text-right">110-C Shields Park Drive, Kernersville</span>
            <span class="text-right">NC 27284</span>
          </div>
        </div>
        <div
          class="mt-[34px] flex justify-between"
          :class="[{ 'ml-auto': !hasBillingAddress }]"
        >
          <div
            v-if="hasBillingAddress"
            class="grid gap-x-[40px] gap-y-[25px] text-sm text-lms_black-100"
          >
            <div class="col-start-1">
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                Billed to
              </div>
              <div>
                {{ accountTitle }}
                <br />
              </div>
            </div>
            <div class="col-start-2 col-end-5 self-end">
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                &nbsp;
              </div>
              <div>
                {{ billingAddress.line1 }} {{ billingAddress.line2 }}
                <br />
                {{ billingAddress.city }}, {{ billingAddress.state }}
                {{ billingAddress.zip }}
              </div>
            </div>
            <div>
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                Invoice Number
              </div>
              <div>#{{ invoiceNumber }}</div>
            </div>
            <div>
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                Invoice Date
              </div>
              <div>{{ invoiceDate }}</div>
            </div>
            <div v-if="invoiceType === 'payment'">
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                Due Date
              </div>
              <div>{{ dueDate }}</div>
            </div>
            <div v-if="invoiceType === 'order'">
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                Billing Term
              </div>
              <div>{{ billingTerm }}</div>
            </div>
            <div v-if="invoiceType === 'order'">
              <div
                class="text-gpx_label font-semibold uppercase text-gpx_gray-200"
              >
                Payment Term
              </div>
              <div>{{ paymentTerm }}</div>
            </div>
          </div>
          <div v-if="invoiceType === 'payment'" class="flex flex-col items-end">
            <div
              class="mb-px text-gpx_label font-semibold uppercase text-gpx_gray-200"
            >
              Amount Due
            </div>
            <div class="text-gpx_black-400 text-2xl leading-[24px]">
              $<span class="font-bold">{{ formatMoney(payment.amount) }}</span>
            </div>
          </div>
        </div>
        <table class="mt-[67px] table w-full table-auto">
          <thead>
            <tr>
              <th
                class="bg-gpx_gray-900 pl-[24px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
              ></th>
              <th
                class="bg-gpx_gray-900 py-[18px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
              >
                <div
                  class="whitespace-nowrap text-left text-gpx_label font-medium uppercase leading-[20px] text-gpx_gray-200"
                >
                  #
                </div>
              </th>
              <th
                class="bg-gpx_gray-900 py-[18px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
              >
                <div
                  class="whitespace-nowrap text-left text-gpx_label font-medium uppercase leading-[20px] text-gpx_gray-200"
                >
                  Description
                </div>
              </th>
              <th
                class="bg-gpx_gray-900 py-[18px] pr-[56px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
              >
                <div
                  class="whitespace-nowrap text-right text-gpx_label font-medium uppercase leading-[20px] text-gpx_gray-200"
                >
                  Unit Cost
                </div>
              </th>
              <th
                class="bg-gpx_gray-900 py-[18px] pr-[56px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
              >
                <div
                  class="whitespace-nowrap text-right text-gpx_label font-medium uppercase leading-[20px] text-gpx_gray-200"
                >
                  Qty
                </div>
              </th>
              <th
                class="bg-gpx_gray-900 py-[18px] pr-[40px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
              >
                <div
                  class="whitespace-nowrap text-right text-gpx_label font-medium uppercase leading-[20px] text-gpx_gray-200"
                >
                  Line Total
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="invoiceItems">
            <tr
              v-for="(item, index) in invoiceItems"
              :key="item.id"
              class="text-gpx_sm"
            >
              <td class="pl-[24px]"></td>
              <td
                class="border-b border-b-gpx_gray-800 !py-[18px] pr-[24px] !font-medium text-gpx_gray-300"
              >
                {{ index + 1 }}.
              </td>
              <td
                class="text-gpx_black-400 w-full border-b border-b-gpx_gray-800 !py-[18px] !font-semibold"
              >
                {{ item.title }}
              </td>
              <td
                class="text-gpx_black-500 border-b border-b-gpx_gray-800 !py-[18px] !pr-[56px] text-right"
              >
                ${{ formatMoney(item.price) }}
              </td>
              <td
                class="text-gpx_black-500 border-b border-b-gpx_gray-800 !py-[18px] !pr-[56px] text-right"
              >
                {{ item.qty }}
              </td>
              <td
                class="text-gpx_black-500 border-b border-b-gpx_gray-800 !py-[18px] !pr-[40px] text-right"
              >
                ${{ formatMoney(item.total) }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="order">
            <tr class="col-span-2 text-xs font-medium text-gpx_gray-300">
              <td colspan="3"></td>
              <td colspan="2" class="pr-[56px] pt-[19px] text-right">
                Hardware:
              </td>
              <td class="pr-[40px] pt-[19px] text-right">
                <s
                  v-if="
                    order.summary.hardware_total !==
                    order.summary.hardware_before_discount
                  "
                >
                  ${{ formatMoney(order.summary.hardware_before_discount) }}
                </s>
                ${{ formatMoney(order.summary.hardware_total) }}
              </td>
            </tr>
            <tr class="col-span-2 text-xs font-medium text-gpx_gray-300">
              <td colspan="3"></td>
              <td colspan="2" class="pr-[56px] pt-[6px] text-right">
                Subscription:
              </td>
              <td class="pr-[40px] pt-[6px] text-right">
                <s
                  v-if="
                    order.summary.subscription_total !==
                    order.summary.subscription_before_discount
                  "
                >
                  ${{ formatMoney(order.summary.subscription_before_discount) }}
                </s>
                ${{ formatMoney(order.summary.subscription_total) }}
              </td>
            </tr>
            <tr class="col-span-2 text-xs font-medium text-gpx_gray-300">
              <td colspan="3"></td>
              <td colspan="2" class="pr-[56px] pt-[6px] text-right">
                Live Audio Hours:
              </td>
              <td class="pr-[40px] pt-[6px] text-right">
                <s
                  v-if="
                    order.summary.audio_hour_total !==
                    order.summary.audio_hour_before_discount
                  "
                >
                  ${{ formatMoney(order.summary.audio_hour_before_discount) }}
                </s>
                ${{ formatMoney(order.summary.audio_hour_total) }}
              </td>
            </tr>
            <tr class="col-span-2 text-xs font-medium text-gpx_gray-300">
              <td colspan="3"></td>
              <td colspan="2" class="pr-[56px] pt-[6px] text-right">
                Shipping:
              </td>
              <td class="pr-[40px] pt-[6px] text-right">
                <s
                  v-if="
                    order.summary.shipping_total !==
                    order.summary.shipping_before_discount
                  "
                >
                  ${{ formatMoney(order.summary.shipping_before_discount) }}
                </s>
                ${{ formatMoney(order.summary.shipping_total) }}
              </td>
            </tr>
            <tr class="col-span-2 text-xs font-medium text-gpx_gray-300">
              <td colspan="3"></td>
              <td colspan="2" class="pr-[56px] pt-[6px] text-right">Tax:</td>
              <td class="pr-[40px] pt-[6px] text-right">
                ${{ formatMoney(order.summary.tax) }}
              </td>
            </tr>
            <tr
              v-if="order.summary.promo_code"
              class="col-span-2 text-xs font-medium text-gpx_gray-300"
            >
              <td colspan="3"></td>
              <td colspan="2" class="pr-[56px] pt-[6px] text-right">
                Promo discount:
              </td>
              <td class="pr-[40px] pt-[6px] text-right">
                <em v-if="order.summary.promo_code.type === 'dollar'">$</em>
                <em v-if="order.summary.promo_code.type === 'discount'">%</em>
                {{ order.summary.promo_code.amount }}
              </td>
            </tr>
            <tr class="h-[19px] w-full" />
            <tr
              class="col-span-2 mt-[19px] text-xs font-medium text-gpx_gray-300"
            >
              <td></td>
              <td></td>
              <td></td>
              <td
                colspan="2"
                class="border-t border-b-gpx_gray-800 pr-[56px] pt-[14px] text-right text-base font-semibold text-lms_black-100"
              >
                Grand Total:
              </td>
              <td
                class="border-t border-b-gpx_gray-800 pr-[40px] pt-[14px] text-right text-base font-semibold text-lms_black-100"
              >
                <span class="font-normal">$</span
                >{{ formatMoney(order.summary.grand_total) }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div
      class="overflow-hidden rounded-b-[10px] bg-white pt-[40px] sm:pt-[64px]"
    >
      <div
        v-if="canPay"
        class="flex justify-between bg-bg-1 px-[40px] pb-[35px] pt-[29px]"
      >
        <div>
          <div class="mb-4 text-lg font-semibold text-lms_black-500">
            Payment schedule
          </div>
          <div class="text-sm text-gpx_gray-100" v-if="upfrontPayment">
            Due upfront:
            <span class="text-lms_black-100"
              >${{ formatMoney(upfrontPayment.amount) }}</span
            >
          </div>
          <div class="text-sm text-gpx_gray-100" v-if="monthlyPayment">
            {{ monthlyPaymentsNumber }} monthly payments of:
            <span class="text-lms_black-100"
              >${{ formatMoney(monthlyPayment.amount) }}</span
            >
          </div>
        </div>
        <div class="ml-auto text-right">
          <UIButton
            class="mb-[15px]"
            label="Continue to Payment"
            @click="handleGoToPayment"
          />
          <div class="text-xs text-lms_black-100">
            <div class="flex" v-if="upfrontPayment">
              <ClockIcon class="mr-1 size-[16px] text-gpx_gray-300" />
              <div>
                Payment due on
                {{ formatTime(upfrontPayment.due_date, 'MMMM d, yyyy') }}
              </div>
            </div>
            <div v-if="monthlyPayment" class="flex">
              <ClockIcon class="mr-1 size-[16px] text-gpx_gray-300" />
              <div>
                First monthly payment due on
                {{ formatTime(monthlyPayment.due_date, 'MMMM d, yyyy') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UIButtonNew
        v-else
        size="modal"
        :is-loading="isFetchingInvoice"
        is-loading-text="Generating invoice..."
        @click="handleDownloadInvoice"
      >
        Download .PDF
      </UIButtonNew>
    </div>
  </ModalLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { XMarkIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { useToast } from 'vue-toastification';
import GPXLogo from '@/assets/gpx-logo.svg';
import LogistimaticsLogo from '@/assets/logo.webp';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { formatMoney, formatTime } from '@/filters';
import { billingTermMap, paymentTermMap } from '@/config/constants';
import { is_b2b_platform } from '@/utils/b2b-account';

const toast = useToast();

export default {
  components: {
    ModalLayout,
    XMarkIcon,
    ClockIcon,
  },

  props: {
    params: Object,
  },

  data() {
    return {
      isFetchingInvoice: false,
    };
  },

  computed: {
    ...mapGetters({
      orderById: 'order/byId',
    }),
    orderId() {
      return this.params.orderId;
    },
    order() {
      return this.orderById(this.orderId);
    },
    payment() {
      return this.order.payments[0];
    },
    account() {
      return this.order.account;
    },
    accountTitle() {
      return this.account.title;
    },
    logo() {
      return is_b2b_platform ? GPXLogo : LogistimaticsLogo;
    },
    hasBillingAddress() {
      return Boolean(this.order.billingAddress);
    },
    billingAddress() {
      return {
        line1: _.get(this.order, 'billingAddress.line1', '-'),
        line2: _.get(this.order, 'billingAddress.line2', ''),
        city: _.get(this.order, 'billingAddress.city', ''),
        state: _.get(this.order, 'billingAddress.state', ''),
        zip: _.get(this.order, 'billingAddress.zip', ''),
      };
    },
    invoiceNumber() {
      const invoiceId = [_.padStart(this.order.id, 6, '0')];
      if (this.payment) {
        invoiceId.push(_.padStart(this.payment.id, 6, '0'));
      }
      return invoiceId.join('-');
    },
    invoiceDate() {
      if (this.payment) {
        return formatTime(this.payment.created_at);
      }
      return formatTime(this.order.created_at);
    },
    dueDate() {
      const timestamp = this.payment?.due_date;
      if (!timestamp) return '-';
      return formatTime(timestamp);
    },
    invoiceType() {
      if (this.payment) {
        return 'payment';
      }
      return 'order';
    },
    billingTerm() {
      return billingTermMap[this.order.billingTerm];
    },
    paymentTerm() {
      return paymentTermMap[this.order?.paymentTerm];
    },
    invoiceItems() {
      return this.order.invoiceItems;
    },
    upfrontPayment() {
      return this.order.billingTerm !== 'monthly'
        ? this.order.payments[0]
        : null;
    },
    monthlyPayment() {
      if (this.order.billingTerm === 'up-front') return null;
      return this.order.billingTerm === 'monthly'
        ? this.order.payments[0]
        : this.order.payments[1];
    },
    monthlyPaymentsNumber() {
      switch (this.order.billingTerm) {
        case 'monthly':
          return this.order.payments.length;
        case 'device-up-front':
          return this.order.payments.length - 1;
        default:
          return 0;
      }
    },
    canPay() {
      return this.params.mode === 'pay';
    },
  },

  methods: {
    formatTime,
    formatMoney,
    ...mapActions({
      openModal: 'modal/open',
      closeModal: 'modal/close',
      fetchInvoice: 'payment/fetchInvoice',
    }),
    handleDownloadInvoice() {
      this.isFetchingInvoice = true;
      this.fetchInvoice({ orderId: this.order.id, paymentId: this.payment?.id })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isFetchingInvoice = false;
        });
    },
    handleGoToPayment() {
      if (!this.invoiceItems.length) {
        return toast.error('This order has no items');
      }
      this.openModal({
        name: 'ManageOrderPaymentModal',
        params: {
          order: this.order,
          upfrontPayment: this.upfrontPayment,
          monthlyPayment: this.monthlyPayment,
          monthlyPaymentsNumber: this.monthlyPaymentsNumber,
          goBackAttrs: {
            label: 'Back to Invoice',
            onClick: () => this.closeModal('ManageOrderPaymentModal'),
          },
          redirectAttrs: {
            path: 'payment-success',
            hash: 'orders',
          },
        },
      });
    },
  },
};
</script>
