import { BATCH_ADD_OR_UPDATE, REMOVE, SET_DEFAULT } from '../mutation-types';
import {
  all,
  byId,
  byIds,
  addOrUpdateItems,
  processAndReturn,
  removeItem,
} from '../helpers';
import service from '@/services';
import * as events from '@/events';

export const state = {
  items: [],
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => byId(state.items, id),
  byIds: (state) => (ids) => byIds(state.items, ids),
};

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
  [SET_DEFAULT]: (state, item) => {
    state.items = state.items.map((paymentMethod) => {
      if (paymentMethod.id !== item.id) {
        return {
          ...paymentMethod,
          default: false,
        };
      }

      return {
        ...paymentMethod,
        default: true,
      };
    });
  },
};

export const actions = {
  fetch: (store, accountId) => {
    return service
      .get(`accounts/${accountId}/payment-methods`)
      .then((res) => processAndReturn(res, 'paymentMethod'));
  },
  clear: ({ state }) => {
    state.items = [];
  },
  create: (store, accountId) => {
    return service.post(`accounts/${accountId}/payment-methods`).then((res) => {
      events.trigger(events.names.PAYMENT_METHOD_CREATION_INITIATED);
      return res.data.data;
    });
  },
  delete: (store, { accountId, paymentMethodId }) => {
    return service
      .delete(`accounts/${accountId}/payment-methods/${paymentMethodId}`)
      .then((response) => {
        store.commit(REMOVE, { id: paymentMethodId });
        events.trigger(events.names.PAYMENT_METHOD_DELETED, {
          paymentMethod: paymentMethodId,
        });
        return response;
      });
  },
  setAsDefault: (store, { accountId, paymentMethodId }) => {
    return service
      .put(`accounts/${accountId}/payment-methods/${paymentMethodId}`)
      .then(() => {
        store.commit(SET_DEFAULT, { id: paymentMethodId });
        events.trigger(events.names.PAYMENT_METHOD_SET_AS_DEFAULT, {
          paymentMethod: paymentMethodId,
        });
      });
  },
  refreshSetupIntent(store, { setupIntentId }) {
    service.post(`setup-intents/${setupIntentId}/refresh`);
  },
};
