<template>
  <OrderLayout>
    <CommonNotification
      v-if="notification.value"
      :label="notification.label"
      :value="notification.value"
      :desc="notification.desc"
      :button="notification.button"
    />
    <div
      class="mx-auto mb-[120px] max-w-[908px] xs:mt-[128px]"
      v-if="loaded && order"
    >
      <div
        class="rounded-t-[10px] border-b-0 border-gpx_gray-800 px-6 pb-[48px] pt-[41px] xs:border xs:px-[40px]"
      >
        <div class="border-b border-b-gpx_gray-800 pb-[18px]">
          <div class="mb-[2px] text-sm !font-medium text-gpx_gray-200">
            Viewing invoice for
          </div>
          <div class="text-2xl !font-semibold text-lms_black-500">
            Order #{{ order.id }}
          </div>
          <div
            v-if="order?.admin_form_details?.po_number"
            class="text-sm text-gpx_gray-100"
          >
            {{ order.admin_form_details?.po_number }}
          </div>
        </div>
        <div class="mt-[35px] flex flex-col">
          <div class="flex justify-between">
            <img
              class="h-7 w-auto xs:h-8"
              src="@/assets/gpx-logo.svg"
              alt="GPX"
            />
            <div class="flex flex-col items-end text-gpx_xs text-gpx_gray-300">
              <span class="text-right">110-C Shields Park Drive, Kernersville</span>
              <span class="text-right">NC 27284</span>
            </div>
          </div>
          <div class="mt-[33px] flex justify-between">
            <div
              class="grid gap-x-[40px] gap-y-[25px] text-gpx_sm text-lms_black-100"
            >
              <template v-if="order.billingAddress">
                <div class="col-start-1">
                  <div
                    class="text-gpx_label !font-semibold uppercase text-gpx_gray-200"
                  >
                    Billed to
                  </div>
                  <div>
                    {{ order.billingAddress.first_name }}
                    {{ order.billingAddress.last_name }}
                    <br />
                    {{ order.billingAddress.line2 }}
                  </div>
                </div>
                <div class="col-start-2 col-end-4 self-end">
                  {{ order.billingAddress.line1 }},
                  {{ order.billingAddress.city }},
                  <br />
                  {{ order.billingAddress.state }}
                  {{ order.billingAddress.zip }},
                  {{ order.billingAddress.country }}
                </div>
              </template>
              <div>
                <div
                  class="text-gpx_label !font-semibold uppercase text-gpx_gray-200"
                >
                  Invoice Number
                </div>
                <div>#{{ invoiceNumber }}</div>
              </div>
              <div>
                <div
                  class="text-gpx_label !font-semibold uppercase text-gpx_gray-200"
                >
                  Invoice Date
                </div>
                <div>{{ invoiceDate }}</div>
              </div>
              <div>
                <div
                  class="text-gpx_label !font-semibold uppercase text-gpx_gray-200"
                >
                  Due Date
                </div>
                <div>{{ dueDate }}</div>
              </div>
            </div>
            <div class="flex flex-col items-end">
              <div
                class="mb-px text-gpx_label !font-semibold uppercase text-gpx_gray-200"
              >
                Amount Due
              </div>
              <div class="text-2xl leading-[24px] text-lms_black-300">
                $<span class="font-bold"
                  >{{ formatMoney(order.summary.grand_total) }}
                </span>
              </div>
            </div>
          </div>
          <table
            class="!mb-0 mt-[51px] block w-full table-auto overflow-x-auto xs:table xs:overflow-x-visible"
          >
            <thead>
              <tr>
                <th
                  class="bg-gpx_gray-900 !pl-[24px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
                ></th>
                <th
                  class="bg-gpx_gray-900 !py-[18px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
                >
                  <div
                    class="whitespace-nowrap text-left text-gpx_label !font-medium uppercase leading-[20px] text-gpx_gray-200"
                  >
                    #
                  </div>
                </th>
                <th
                  class="bg-gpx_gray-900 !py-[18px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
                >
                  <div
                    class="whitespace-nowrap text-left text-gpx_label !font-medium uppercase leading-[20px] text-gpx_gray-200"
                  >
                    Description
                  </div>
                </th>
                <th
                  class="bg-gpx_gray-900 !py-[18px] !pr-[56px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
                >
                  <div
                    class="whitespace-nowrap text-right text-gpx_label !font-medium uppercase leading-[20px] text-gpx_gray-200"
                  >
                    Unit Cost
                  </div>
                </th>
                <th
                  class="bg-gpx_gray-900 !py-[18px] !pr-[56px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
                >
                  <div
                    class="whitespace-nowrap text-right text-gpx_label !font-medium uppercase leading-[20px] text-gpx_gray-200"
                  >
                    Qty
                  </div>
                </th>
                <th
                  class="bg-gpx_gray-900 !py-[18px] !pr-[40px] first-of-type:rounded-l-[6px] last-of-type:rounded-r-[6px]"
                >
                  <div
                    class="whitespace-nowrap text-left text-gpx_label !font-medium uppercase leading-[20px] text-gpx_gray-200"
                  >
                    Line Total
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(invoiceItem, $i) in order.invoiceItems"
                :key="invoiceItem.id"
                class="text-gpx_sm"
              >
                <td class="pl-[24px]"></td>
                <td
                  class="border-b border-b-gpx_gray-800 !py-[18px] pr-[24px] !font-medium text-gpx_gray-300"
                >
                  {{ $i + 1 }}.
                </td>
                <td
                  class="w-full border-b border-b-gpx_gray-800 !py-[18px] !font-semibold text-lms_black-300"
                >
                  {{ invoiceItem.title }}
                </td>
                <td
                  class="border-b border-b-gpx_gray-800 !py-[18px] !pr-[56px] text-right text-lms_black-100"
                >
                  ${{ formatMoney(invoiceItem.price) }}
                </td>
                <td
                  class="border-b border-b-gpx_gray-800 !py-[18px] !pr-[56px] text-right text-lms_black-100"
                >
                  {{ invoiceItem.qty }}
                </td>
                <td
                  class="border-b border-b-gpx_gray-800 !py-[18px] !pr-[40px] text-right text-lms_black-100"
                >
                  ${{ formatMoney(invoiceItem.total) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="col-span-2 text-gpx_xs !font-medium text-gpx_gray-300">
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td
                  colspan="2"
                  class="!border-t-0 !pb-0 !pl-0 !pr-[56px] !pt-[19px] text-right"
                >
                  Hardware subtotal:
                </td>
                <td
                  class="!border-t-0 !pb-0 !pl-0 !pr-[40px] !pt-[19px] text-right"
                >
                  <s
                    v-if="
                      order.summary.hardware_total !==
                      order.summary.hardware_before_discount
                    "
                  >
                    ${{ formatMoney(order.summary.hardware_before_discount) }}
                  </s>
                  ${{ formatMoney(order.summary.hardware_total) }}
                </td>
              </tr>
              <tr class="col-span-2 text-gpx_xs !font-medium text-gpx_gray-300">
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td
                  colspan="2"
                  class="!border-t-0 !pb-0 !pl-0 !pr-[56px] !pt-[6px] text-right"
                >
                  Subscription subtotal:
                </td>
                <td
                  class="!border-t-0 !pb-0 !pl-0 !pr-[40px] !pt-[6px] text-right"
                >
                  <s
                    v-if="
                      order.summary.subscription_total !==
                      order.summary.subscription_before_discount
                    "
                  >
                    ${{
                      formatMoney(order.summary.subscription_before_discount)
                    }}
                  </s>
                  ${{ formatMoney(order.summary.subscription_total) }}
                </td>
              </tr>
              <tr class="col-span-2 text-gpx_xs !font-medium text-gpx_gray-300">
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td
                  colspan="2"
                  class="!border-t-0 !pb-0 !pl-0 !pr-[56px] !pt-[6px] text-right"
                >
                  Live audio hours:
                </td>
                <td
                  class="!border-t-0 !pb-0 !pl-0 !pr-[40px] !pt-[6px] text-right"
                >
                  <s
                    v-if="
                      order.summary.live_audio_total !==
                      order.summary.live_audio_before_discount
                    "
                  >
                    ${{ formatMoney(order.summary.live_audio_before_discount) }}
                  </s>
                  ${{ formatMoney(order.summary.live_audio_total) }}
                </td>
              </tr>
              <tr class="col-span-2 text-gpx_xs !font-medium text-gpx_gray-300">
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td
                  colspan="2"
                  class="!border-t-0 !pl-0 !pr-[56px] !pt-[6px] text-right"
                >
                  Shipping:
                </td>
                <td class="!border-t-0 !pl-0 !pr-[40px] !pt-[6px] text-right">
                  <s
                    v-if="
                      order.summary.shipping_total !==
                      order.summary.shipping_before_discount
                    "
                  >
                    ${{ formatMoney(order.summary.shipping_before_discount) }}
                  </s>
                  ${{ formatMoney(order.summary.shipping_total) }}
                </td>
              </tr>
              <tr class="col-span-2 text-xs font-medium text-gpx_gray-300">
                <td colspan="3"></td>
                <td colspan="2" class="pr-[56px] pt-[6px] text-right">Tax:</td>
                <td class="pr-[40px] pt-[6px] text-right">
                  ${{ formatMoney(order.summary.tax) }}
                </td>
              </tr>
              <tr
                v-if="order.summary.promo_code"
                class="col-span-2 text-xs font-medium text-gpx_gray-300"
              >
                <td colspan="3"></td>
                <td colspan="2" class="pr-[56px] pt-[6px] text-right">
                  Promo discount:
                </td>
                <td class="pr-[40px] pt-[6px] text-right">
                  <template
                    v-if="order.summary.promo_code.type === 'free_hardware'"
                  >
                    {{ order.summary.promo_code.amount }} free hardware
                  </template>
                  <template v-else>
                    <em v-if="order.summary.promo_code.type === 'dollar'">$</em>
                    <em v-if="order.summary.promo_code.type === 'discount'">
                      %
                    </em>
                    {{ order.summary.promo_code.amount }}
                  </template>
                </td>
              </tr>
              <tr class="h-[19px] w-full" />
              <tr class="col-span-2 text-gpx_xs !font-medium text-gpx_gray-300">
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td class="!border-t-0"></td>
                <td
                  colspan="2"
                  class="!border-t !border-t-gpx_gray-800 !pb-0 !pl-0 !pr-[56px] !pt-[14px] text-right text-gpx_base !font-semibold text-lms_black-100"
                >
                  Grand Total:
                </td>
                <td
                  class="!border-t !border-t-gpx_gray-800 !pb-0 !pl-0 !pr-[40px] !pt-[14px] text-right text-gpx_base !font-semibold text-lms_black-100"
                >
                  <span class="font-normal">$</span
                  >{{ formatMoney(order.summary.grand_total) }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div
        class="block justify-between rounded-b-[10px] bg-bg-1 px-[40px] pb-[35px] pt-[29px] xs:flex"
      >
        <div
          v-if="isPayable"
          class="mb-3 flex flex-col xs:mb-0 xs:gap-y-[16px]"
        >
          <div class="text-gpx_md font-semibold text-lms_black-500">
            Payment schedule
          </div>
          <div class="flex flex-col gap-y-[4px]">
            <div class="text-gpx_sm text-gpx_gray-100" v-if="upfrontPayment">
              <span>Due Upfront:&nbsp;</span>
              <span class="text-lms_black-100"
                >${{ formatMoney(upfrontPayment.amount) }}</span
              >
            </div>
            <div class="text-gpx_sm text-gpx_gray-100" v-if="regularPayment">
              <span>{{ regularPaymentsDescription }}</span>
              <span class="text-lms_black-100"
                >${{ formatMoney(regularPayment.amount) }}</span
              >
            </div>
          </div>
        </div>
        <div
          v-if="isPayable"
          class="flex flex-col items-start gap-y-[6px] pt-[3px] xs:items-end xs:gap-y-[15px]"
        >
          <button
            class="rounded-[6px] bg-lms_red-900 px-[40px] py-[12px]"
            @click="orderPaymentModal = true"
          >
            <span class="text-gpx_sm font-semibold text-white"
              >Continue to Payment</span
            >
          </button>
          <div class="flex items-center gap-x-[4px]">
            <ClockIcon class="size-[16px] text-gpx_gray-300" />
            <div v-if="upfrontPayment" class="text-xs text-lms_black-100">
              Payment due on
              {{ upfrontPaymentDueDate }}
            </div>
            <div v-else class="text-xs text-lms_black-100">
              First monthly payment due on
              {{ regularPaymentDueDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded && !order">Not found</div>
    <ApproveOrderPaymentModal
      :order="order"
      :upfrontPayment="upfrontPayment"
      :regularPayment="regularPayment"
      :regularPaymentsNumber="regularPaymentsNumber"
      @close="orderPaymentModal = false"
      v-if="orderPaymentModal"
    />
  </OrderLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { ClockIcon } from '@heroicons/vue/24/outline';
import OrderLayout from '@/layouts/OrderLayout.vue';
import ApproveOrderPaymentModal from '@/components/manage/order/modals/ApproveOrderPaymentModal.vue';
import CommonNotification from '@/components/_common/CommonNotification.vue';
import { setTitle } from '@/utils/title';
import { formatMoney, formatTime } from '@/filters';
import {
  BILLING_TURN_UPFRONT,
  BILLING_TURN_DEVICE_UPFRONT,
  BILLING_TURN_MONTHLY,
  BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY,
} from '@/config/constants';

export default {
  components: {
    CommonNotification,
    OrderLayout,
    ApproveOrderPaymentModal,
    ClockIcon,
  },
  data: () => {
    return {
      order: null,
      orderPaymentModal: false,
      loaded: false,
      notification: {
        label: 'This order has been sent to you by:',
        value: 'GPX Team',
        desc: 'Order terms & renewal agreement',
        button: {
          value: 'Read More',
          route: 'https://gpx.co/terms-of-service/',
        },
      },
    };
  },
  beforeMount() {
    const params = {
      include: 'shippingAddress,billingAddress,payments',
    };
    this.fetchByUUID({ uuid: this.orderUUID, params })
      .then((res) => {
        this.order = res.data.data;
        this.notification.value =
          this.order.admin_form_details?.sales_person ||
          this.notification.value;
      })
      .finally(() => {
        this.loaded = true;
      });
  },
  computed: {
    ...mapGetters({
      account: 'auth/account',
    }),
    orderUUID() {
      return this.$route.params.uuid;
    },
    payment() {
      return this.order.payments[0];
    },
    isPayable() {
      return _.get(this.order, 'summary.grand_total', null);
    },
    invoiceNumber() {
      const invoiceId = [_.padStart(this.order.id, 6, '0')];
      if (this.payment) {
        invoiceId.push(_.padStart(this.payment.id, 6, '0'));
      }
      return invoiceId.join('-');
    },
    invoiceDate() {
      if (this.payment) {
        return formatTime(this.payment.created_at);
      }
      return formatTime(this.order.created_at);
    },
    dueDate() {
      const timestamp = this.payment?.due_date;
      if (!timestamp) return '-';
      return formatTime(timestamp);
    },
    upfrontPayment() {
      switch (this.order.billingTerm) {
        case BILLING_TURN_MONTHLY:
          return null;
        case BILLING_TURN_UPFRONT:
        case BILLING_TURN_DEVICE_UPFRONT:
        case BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY:
          return this.order.payments[0];
      }
    },
    upfrontPaymentDueDate() {
      if (!this.upfrontPayment.due_date) return '-';
      return formatTime(this.upfrontPayment.due_date);
    },
    regularPayment() {
      switch (this.order.billingTerm) {
        case BILLING_TURN_UPFRONT:
          return null;
        case BILLING_TURN_MONTHLY:
          return this.order.payments[0];
        case BILLING_TURN_DEVICE_UPFRONT:
          return this.order.payments[1];
        case BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY:
          return this.order.payments[1] ?? this.order.payments[0];
      }
    },
    regularPaymentDueDate() {
      if (!this.regularPayment.due_date) return '-';
      return formatTime(this.regularPayment.due_date);
    },
    regularPaymentsNumber() {
      switch (this.order.billingTerm) {
        case BILLING_TURN_MONTHLY:
          return this.order.payments.length;
        case BILLING_TURN_DEVICE_UPFRONT:
        case BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY:
          return this.order.payments.length - 1;
        default:
          return 0;
      }
    },
    regularPaymentsDescription() {
      switch (this.order.billingTerm) {
        case BILLING_TURN_MONTHLY:
        case BILLING_TURN_DEVICE_UPFRONT:
          return this.regularPaymentsNumber + ' monthly payments of: ';
        case BILLING_TURN_DEVICE_UPFRONT_SUBSCRIPTION_ANNUALLY:
          return this.regularPaymentsNumber + ' monthly payments of: ';
      }
    },
  },
  created() {
    setTitle('Approve Order');
  },
  methods: {
    formatTime,
    formatMoney,
    ...mapActions({
      fetchByUUID: 'orders/fetchByUUID',
    }),
    formatDate(string, mask = 'MM/dd/yyyy') {
      return format(new Date(string), mask);
    },
  },
};
</script>

<style scoped>
thead th,
tbody td {
  @apply !border-t-0;
}

thead th {
  @apply !border-b-0;
}

tfoot td {
  @apply font-medium;
}
</style>
