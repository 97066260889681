<script setup lang="ts">
import * as _ from 'lodash';
import { useStore } from 'vuex';
import DeviceList from '@/components/device/list/DeviceList.vue';
import { useToast } from 'vue-toastification';
import CommonBottomButtonSection from '@/components/_common/CommonBottomButtonSection.vue';
import NoData from '@/components/_common/NoData.vue';
import {
  COMMON_DELETE_ERROR,
  COMMON_DELETE_SUCCESS,
  COMMON_REMOVE_ERROR,
  COMMON_REMOVE_SUCCESS,
  MESSAGE403,
} from '@/config/constants';
import UIIconButton from '@/components/ui/UIIconButton.vue';
import { setTitle } from '@/utils/title';
import * as events from '@/events';
import { useDevice } from '@/composables/useDevice.ts';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGroup } from '@/composables/useGroup.ts';
import LoaderBlock from '@/components/_common/LoaderBlock.vue';
import { useAbility } from '@casl/vue';
import UIMenuButton from "@/components/ui/UIMenuButton.vue";

const toast = useToast();

const route = useRoute();
const router = useRouter();

const store = useStore();

const deviceStore = useDevice();
const groupStore = useGroup();

const { selectedIds, devices } = storeToRefs(deviceStore);
const { resultDeviceIds, isLoading } = storeToRefs(groupStore);

const { setSelectedIds: setDeviceSelectedIds } = deviceStore;
const { setSelectedIds: setGroupSelectedIds } = groupStore;

const {
  getById,
  deleteGroup,
  removeDevices,
  loadDeviceIdsByGroups,
  clearResultDeviceIds,
  setAccountId,
} = groupStore;

setTitle('All Trackers');
setDeviceSelectedIds([]);
setGroupSelectedIds([]);
clearResultDeviceIds();

const accountId = computed(() => store.getters['auth/accountId']);
const group = computed(() => getById(+route.params.id));
const { can } = useAbility();
const actions = computed(() => [
  {
    icon: 'TrashIcon',
    label: 'Delete Group',
    action: {
      mode: 'fn',
      fn: () => {
        if (!can('manage', 'Groups')) {
          toast.error(MESSAGE403);
          return;
        }
        store.dispatch('modal/open', {
          name: 'CommonConfirmationModal',
          params: {
            actionLabel: 'Delete',
            title: group.value.name,
            body: 'Are you sure you want to delete this group?',
            onOk: () => deleteGroup(accountId.value, group.value.id),
            callback: () => router.push({ name: 'GroupListView' }),
            successMessage: COMMON_DELETE_SUCCESS,
            errorMessage: COMMON_DELETE_ERROR,
          },
        });
      },
    },
  },
]);

onMounted(() => {
  setAccountId(accountId.value);
  loadDeviceIdsByGroups([+route.params.id]);
  events.trigger(events.names.GROUP_VIEWED, { group: group.value });
});
onUnmounted(() => {
  setGroupSelectedIds([]);
  setDeviceSelectedIds([]);
  clearResultDeviceIds();
});

const handleAddTrackersClick = () => {
  if (!can('manage', 'Groups')) {
    toast.error(MESSAGE403);
    return;
  }
  store.dispatch('modal/open', {
    name: 'DeviceGroupModal',
    params: {
      actionType: 'add',
      id: +route.params.id,
      name: group.value.name,
      step: 1,
    },
  });
};

const handleEditGroupClick = () => {
  if (!can('manage', 'Groups')) {
    toast.error(MESSAGE403);
    return;
  }
  store.dispatch('modal/open', {
    name: 'DeviceGroupModal',
    params: {
      actionType: 'swap',
      id: +route.params.id,
      name: group.value.name,
      step: 1,
      device_ids: group.value.device_ids,
    },
  });
};

const handleRemoveSelected = () => {
  if (!can('manage', 'Groups')) {
    toast.error(MESSAGE403);
    return;
  }
  const names = selectedIds.value.map((id) => {
    const device = devices.value.get(id);
    return _.get(device, 'name', 'No Name');
  });
  const labelName =
    selectedIds.value.length === resultDeviceIds.value.length
      ? ['all devices']
      : names;

  store.dispatch('modal/open', {
    name: 'CommonConfirmationModal',
    params: {
      actionLabel: 'Remove Devices',
      title: `${selectedIds.value.length} device(s)`,
      body: `Are you sure you want to remove ${labelName.join(
        ', ',
      )} from this group?`,
      onOk: () =>
        removeDevices(accountId.value, group.value.id, {
          device_ids: selectedIds.value,
        }),
      successMessage: COMMON_REMOVE_SUCCESS,
      errorMessage: COMMON_REMOVE_ERROR,
    },
  });
};
</script>

<template>
  <div class="content-scrollable">
    <LoaderBlock v-if="isLoading" />
    <NoData
      v-else-if="!resultDeviceIds || resultDeviceIds.length === 0"
      title="There are no trackers"
      subtitle="Your trackers list is empty"
    >
      <UIMenuButton
        v-if="$can('manage', 'Groups')"
        class="mb-1"
        mainIcon="SquaresPlusIcon"
        mainLabel="Add Trackers"
        @click="handleAddTrackersClick"
        :options="actions"
        fullWidth
      />
    </NoData>
    <DeviceList :device-ids="resultDeviceIds" class="pb-24" v-else />
  </div>

  <CommonBottomButtonSection v-if="$can('manage', 'Groups') && resultDeviceIds?.length">
    <div
      v-if="selectedIds.length"
      class="flex items-center justify-between gap-x-5"
    >
      <div class="flex items-center">
        <UIIconButton
          icon="XMarkIcon"
          class="mr-[2px]"
          tooltip="Clear selection"
          @click="setDeviceSelectedIds([])"
        />
        <div class="text-[13px] text-lgmx_slate-900">
          {{ selectedIds.length }} selected
        </div>
      </div>
      <UIButtonNew icon="TrashIcon" @click="handleRemoveSelected">
        Remove
      </UIButtonNew>
    </div>
    <UIMenuButton
      v-else
      mainIcon="SquaresPlusIcon"
      mainLabel="Edit Group"
      @click="handleEditGroupClick"
      :options="actions"
      fullWidth
    />
  </CommonBottomButtonSection>
</template>
