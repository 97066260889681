<script setup>
  import Combobox from '../elements/Combobox.vue';
  import {map} from "lodash";

  const model = defineModel();
  const props = defineProps(['groups'])

  const options = props.groups.map(group => ({
    label: group.name,
    value: group.id,
  }))
  const selected = model.value.map(id => options.find(option => option.value === id));
  const handleSelected = (newSelected) => {
    model.value = map(newSelected || [], option => option.value)
  }
</script>

<template>
  <div>
    <Combobox
        :placeholder="'Start typing...'"
        :options="options"
        :selected-options="selected"
        @update:modelValue="handleSelected"
        label="Select Groups"
        multiple
    />
  </div>
</template>
