import L from 'leaflet';
import { addMinutes, differenceInDays } from 'date-fns';
import { formatTime } from '@/filters';
import { reverseCoords } from '@/utils/coords';
import * as newMarkersPack from '@/utils/map/new-markers';
import * as markersPack from '@/utils/map/markers';
import { getGoogleMapsLink } from '@/utils/map/google';
import * as chargeUtils from '@/utils/map/charge';
import { fixIconMap } from '@/config/constants';
import { celsiusToFahrenheit } from '@/utils/temperature.ts';

export const clusterOptions = {
  maxClusterRadius: 40,
  polygonOptions: {
    fillColor: '#df949e99',
    color: '#df949e',
    weight: 1,
  },
};

export function createMarker(device, position, options = {}) {
  const latlng = reverseCoords(position.location);
  const diff = differenceInDays(
    new Date(),
    new Date(position.last_movement_time),
  );
  let isMissedLastUpdate = false;
  const expectedReportMinutes = device?.expected_report_minutes;
  if (expectedReportMinutes) {
    const lastChanceToUpdateAt = addMinutes(
      new Date(position.timestamp),
      expectedReportMinutes,
    );
    isMissedLastUpdate = new Date() > lastChanceToUpdateAt;
  }
  const { fix, is_inside_geofences } = position;

  const iconsPack = options?.isNewIcon ? newMarkersPack : markersPack;
  const icons = iconsPack[fixIconMap[fix]] || iconsPack['DEFAULT_ICONS'];

  let icon;
  if (diff >= 28) {
    icon = icons['red'];
  } else if (diff >= 14) {
    icon = icons['yellow'];
  } else if (isMissedLastUpdate) {
    icon = icons['gray'];
  } else if (is_inside_geofences) {
    icon = icons['green'];
  } else {
    icon = icons['blue'];
  }

  return L.marker(latlng, { id: device.id, icon });
}

export function moveToPosition(marker, position) {
  const latlng = reverseCoords(position.location);
  marker.setLatLng(latlng);
}

export function getPopupContent(device, position?) {
  const name = device?.name ?? '-';
  const battery = position?.battery;
  const address = position?.address ?? '-';
  const timestamp = position?.timestamp;
  const fix = position?.fix ?? 'unknown';
  const updatedAt = timestamp
    ? formatTime(timestamp, 'E MMMM d, yyyy h:mm aaa')
    : '-';
  const location = position?.location ?? [];

  return `
  <div>
    <div class="flex items-center">
      <div class="font-medium text-xs">${name}</div>
      <div class="ml-auto pl-1">
        ${chargeUtils.getChargeEl(battery)}
      </div>
    </div>
    <div class="text-[10px]">
      ${
        fix === 'wifi'
          ? "<div class='text-red-300'>Showing the nearest wi-fi spot.</div>"
          : ''
      }
      ${
        fix === 'cell'
          ? "<div class='text-red-300'>Showing the nearest cell tower.</div>"
          : ''
      }
      <div>
        <a href=${getGoogleMapsLink({
          lat: location[1],
          lng: location[0],
        })} target="_blank">${address}</a>
      </div>
      <div>
        Updated: <span>${updatedAt}</span>
      </div>
      ${contentTemperatureAndHumidity(position)}
    </div>
  </div>
  `;
}

const mapColors = (value: number) => {
  if (value < 10 || value > 90) {
    return 'gpx_red';
  }
  if (value > 10 || value < 40) {
    return 'gpx_yellow';
  }

  return 'gpx_green';
};
function contentTemperatureAndHumidity(position) {
  const temperature = position?.temperature;
  const humidity = position?.humidity;

  let temperatureContent = '';
  if (temperature) {
    temperatureContent = `<div>
        Temperature: <span>${celsiusToFahrenheit(temperature)}°</span>
      </div>`;
  }

  let humidityContent = '';
  if (humidity) {
    humidityContent = `<div>
        Humidity: <span>${humidity}%</span>
      </div>`;
  }

  return temperatureContent + humidityContent;
}

export function getPopupContentWithCreate(device, position) {
  const address = position?.address;
  const location = position?.location ?? [];
  return `
    ${getPopupContent(device, position)}
    ${`
        <div class='text-[10px]'>
          <a href='/geofences/create?area=true' target='_self' onclick='event.preventDefault(); vrouter.push("/geofences/create?label=${address}&lat=${location[1]}&lng=${location[0]}");'>Create geofence</a>
        </div>`}
  `;
}
