import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getApi } from './api';

export default (accountId: Ref<number>) => useQuery({
    queryKey: ['account-devices', () => accountId.value],
    queryFn: () => {
        const { api, baseURL } = getApi();
        return api.get(`/api/accounts/${accountId.value}/devices`, { baseURL }).then(res => res.data.data)
    },
})
