<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { ref} from "vue";

import Button from "../elements/Button.vue";
import PermissionPicker from "./PermissionPicker.vue";

const props = defineProps(['account', 'user', 'isSaving'])
const emit = defineEmits(['close', 'save'])

const role = ref(props.user.pivot.role);
const device_ids = ref(props.user.pivot.device_ids);
const group_ids = ref(props.user.pivot.group_ids);
const full_device_access = ref(props.user.pivot.full_device_access);
</script>

<template>
  <div class="rounded-t-[10px] bg-white pt-[41px]">
    <div
        @click.prevent="emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
    >
      <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
      />
    </div>
    <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
    >
      <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
        Editing {{ user?.first_name || 'User' }} Permissions
      </div>
      <div
          v-if="account"
          class="text-gpx_2xl font-semibold text-lms_black-500"
      >
        {{ account.title }}
      </div>
    </div>
    <div class="px-[24px] sm:px-[40px]">
      <div class="mt-[30px]">
        <div class="mb-[3px] text-gpx_base font-semibold text-lms_black-400">
          User Role
        </div>
        <div class="text-gpx_sm text-gpx_gray-100">
          Grant user access to all or a specific devices.
        </div>
      </div>

      <PermissionPicker
          :accountId="props.account.id"
          v-model:role="role"
          v-model:device-ids="device_ids"
          v-model:group-ids="group_ids"
          v-model:full-device-access="full_device_access"
      ></PermissionPicker>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <Button
          :loading="isSaving"
          :disabled="isSaving"
          :label="isSaving ? 'Saving' : 'Save Settings'"
          @click="() => $emit('save', { role, device_ids, group_ids, full_device_access })"
      />
    </div>
  </div>
</template>