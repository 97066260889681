<script setup>
import isNull from 'lodash/isNull';
import debounce from 'lodash/debounce';
import { ref, watch, computed } from 'vue';
import nprogress from 'nprogress';
import clickOutside from 'click-outside-vue3';
import CommonFiltersButton from '@/components/_common/CommonFiltersButton.vue';
import { usePersonalLocalStorage } from '@/use/use-personal-local-storage';
import * as events from '@/events';
import { useDevice } from '@/composables/useDevice';
import { storeToRefs } from 'pinia';

const deviceStore = useDevice();
const { isAtLeastOneFilterApplied } = storeToRefs(deviceStore);
const { applyFilter } = deviceStore;

const vClickOutside = clickOutside.directive;
const isOpen = ref(false);
const toggleIsOpen = () => (isOpen.value = !isOpen.value);

const filterButtonVariant = computed(() =>
  isAtLeastOneFilterApplied.value ? 'secondary' : 'tertiary',
);

const haveNotReportedDaysFilter = usePersonalLocalStorage(
  'trackers/haveNotReportedDays',
  null,
);
const isHaveNotReportedDaysFilterEnabled = ref(
  Boolean(haveNotReportedDaysFilter.value),
);
const haveNotReportedDays = ref(haveNotReportedDaysFilter.value || 7);

watch(isHaveNotReportedDaysFilterEnabled, (n) => {
  haveNotReportedDaysFilter.value = n ? haveNotReportedDays.value : null;
});
watch(
  haveNotReportedDays,
  debounce(() => {
    haveNotReportedDaysFilter.value = haveNotReportedDays.value;
  }, 500),
);

const batteryFilter = usePersonalLocalStorage('trackers/battery', null);
const isBatteryFilterEnabled = ref(Boolean(batteryFilter.value));
const battery = ref(batteryFilter.value || 100);

watch(isBatteryFilterEnabled, (n) => {
  batteryFilter.value = n ? battery.value : null;
});
watch(
  battery,
  debounce(() => {
    batteryFilter.value = battery.value;
  }, 500),
);

const missedUpdateFilter = usePersonalLocalStorage(
  'trackers/missedUpdate',
  null,
);
const isMissedUpdateFilterEnabled = ref(Boolean(missedUpdateFilter.value));
watch(isMissedUpdateFilterEnabled, (n) => {
  missedUpdateFilter.value = n || null;
});

const includeCellfixesFilter = usePersonalLocalStorage(
  'trackers/includeCellfixes',
  null,
);
const isIncludeCellfixesFilterEnabled = ref(
  Boolean(includeCellfixesFilter.value),
);
watch(isIncludeCellfixesFilterEnabled, (n) => {
  includeCellfixesFilter.value = n || null;
});

const haveMovedDaysFilter = usePersonalLocalStorage(
  'trackers/haveMovedDays',
  null,
);
const isHaveMovedFilterEnabled = ref(Boolean(haveMovedDaysFilter.value));
const haveMovedDays = ref(haveMovedDaysFilter.value || 7);
watch(isHaveMovedFilterEnabled, (n) => {
  haveMovedDaysFilter.value = n ? haveMovedDays.value : null;
});
watch(
  haveMovedDays,
  debounce(() => {
    haveMovedDaysFilter.value = haveMovedDays.value;
  }, 500),
);

const haveNotMovedDaysFilter = usePersonalLocalStorage(
  'trackers/haveNotMovedDays',
  null,
);
const isHaveNotMovedFilterEnabled = ref(Boolean(haveNotMovedDaysFilter.value));
const haveNotMovedDays = ref(haveNotMovedDaysFilter.value || 7);
watch(isHaveNotMovedFilterEnabled, (n) => {
  haveNotMovedDaysFilter.value = n ? haveNotMovedDays.value : null;
});
watch(
  haveNotMovedDays,
  debounce(() => {
    haveNotMovedDaysFilter.value = haveNotMovedDays.value;
  }, 500),
);

const filter = computed(() => ({
  haveNotReportedDays: isNull(haveNotReportedDaysFilter.value)
    ? null
    : Number(haveNotReportedDaysFilter.value),
  battery: isNull(batteryFilter.value) ? null : Number(batteryFilter.value),
  missedUpdate: isNull(missedUpdateFilter.value)
    ? null
    : JSON.parse(missedUpdateFilter.value),
  includeCellfixes: isNull(includeCellfixesFilter.value)
    ? null
    : JSON.parse(includeCellfixesFilter.value),
  haveMovedDays: isNull(haveMovedDaysFilter.value)
    ? null
    : Number(haveMovedDaysFilter.value),
  haveNotMovedDays: isNull(haveNotMovedDaysFilter.value)
    ? null
    : Number(haveNotMovedDaysFilter.value),
}));

applyFilter(filter);
let updateFilter = () => {
  nprogress.start();
  applyFilter(filter);
  nprogress.done();
  events.trigger(events.names.TRACKERS_FILTERED, { filter: filter.value });
};
updateFilter = debounce(updateFilter, 200);

watch(() => filter.value, updateFilter, {
  deep: true,
});
</script>

<template>
  <CommonFiltersButton :variant="filterButtonVariant" @click="toggleIsOpen" />
  <div
    v-if="isOpen"
    class="absolute right-1/2 top-full z-10 mt-1 w-[280px] rounded border border-gray-400 bg-white px-4 py-2 text-[13px] leading-snug shadow-md translate-x-1/2"
    v-click-outside="toggleIsOpen"
  >
    <div class="text-sm capitalize text-gray-750">Filters</div>
    <div class="mb-[2px]">
      <VCheckbox v-model="isHaveNotReportedDaysFilterEnabled">
        <span class="mt-[3px]">
          Hide trackers that have <i>not</i> reported in
          {{ haveNotReportedDays }} days
        </span>
      </VCheckbox>
      <VInputRange
        class="w-full"
        v-model="haveNotReportedDays"
        min="1"
        max="100"
        :disabled="!isHaveNotReportedDaysFilterEnabled"
      />
    </div>
    <div class="mb-[2px]">
      <VCheckbox v-model="isBatteryFilterEnabled">
        <span class="mt-[3px]">
          Hide trackers with battery above {{ battery }}%
        </span>
      </VCheckbox>
      <VInputRange
        class="w-full"
        v-model="battery"
        min="1"
        max="100"
        :disabled="!isBatteryFilterEnabled"
      />
    </div>
    <div class="mb-2">
      <VCheckbox v-model="isMissedUpdateFilterEnabled">
        <span class="mt-[3px]">Show missed update</span>
      </VCheckbox>
    </div>
    <div class="mb-[2px]">
      <VCheckbox v-model="isIncludeCellfixesFilterEnabled">
        <span class="mt-[3px]">Include cell fixes for movement filter</span>
      </VCheckbox>
    </div>
    <div class="mb-[2px]">
      <VCheckbox v-model="isHaveMovedFilterEnabled">
        <span class="mt-[3px]">
          Show trackers that have moved in last {{ haveMovedDays }} days
        </span>
      </VCheckbox>
      <VInputRange
        class="w-full"
        v-model="haveMovedDays"
        min="1"
        max="100"
        :disabled="!isHaveMovedFilterEnabled"
      />
    </div>
    <div class="mb-1">
      <VCheckbox v-model="isHaveNotMovedFilterEnabled">
        <span class="mt-[3px]">
          Show trackers that have <i>not</i> moved in last
          {{ haveNotMovedDays }} days
        </span>
      </VCheckbox>
      <VInputRange
        class="w-full"
        v-model="haveNotMovedDays"
        min="1"
        max="200"
        :disabled="!isHaveNotMovedFilterEnabled"
      />
    </div>
  </div>
</template>
