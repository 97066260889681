<template>
  <AppHeader />
  <div class="relative z-[1] flex w-full flex-col sm:flex-row">
    <router-view name="bar" />
  </div>
  <main class="sm:flex">
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden sm:block sm:w-4/12"
      :class="[
        { '!block': mobileActiveTab === 'left' },
        {
          'pb-[86px] md:pb-[102px]': isBottomButton,
        },
      ]"
    >
      <router-view name="default" />
    </div>
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden sm:block sm:w-8/12"
      :class="[
        { '!block': mobileActiveTab === 'right' },
        {
          'h-[calc(100dvh-42px)] sm:!h-[calc(100dvh-42px)] md:!h-[calc(100dvh-62px)]':
            isTrackView,
        },
      ]"
    >
      <ShipmentMap :view="view" />
    </div>
    <MobileLayoutSwitch type="shipment" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/components/_common/AppHeader.vue';
import ShipmentMap from '@/components/shipment/map/ShipmentMap.vue';
import { SHIPMENT_CATEGORY } from '@/config/constants';
import MobileLayoutSwitch from '@/components/_common/MobileLayoutSwitch.vue';

export default {
  components: {
    AppHeader,
    ShipmentMap,
    MobileLayoutSwitch,
  },

  data() {
    return {
      view: 'cluster',
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      mobileActiveTab: 'shipment/mobileActiveTab',
      activeTab: 'shipment/activeTab',
      generalShipments: 'shipment/filteredGeneral',
      archivedShipments: 'shipment/filteredArchived',
      selectedIdsGeneral: 'shipment/selectedIdsGeneral',
      selectedIdsArchived: 'shipment/selectedIdsArchived',
    }),
    isGeneralTab() {
      return this.activeTab === SHIPMENT_CATEGORY.general;
    },
    isArchivedTab() {
      return this.activeTab === SHIPMENT_CATEGORY.archived;
    },
    isAtLeastOneShipmentSelected() {
      return this.selectedIdsGeneral.length || this.selectedIdsArchived.length;
    },
    isBottomButton() {
      if (this.$route.name === 'ShipmentListView') {
        if (this.isGeneralTab && this.generalShipments.length) {
          return true;
        }
        if (this.isArchivedTab && this.selectedIdsArchived.length) {
          return true;
        }
        if (this.isAtLeastOneShipmentSelected) {
          return true;
        }
      }
      return false;
    },
    isTrackView() {
      return this.$route.name === 'ShipmentTrackView';
    },
  },

  watch: {
    '$route.name': {
      handler(n) {
        if (n === 'ShipmentListView') {
          this.setView('cluster');
        }
        if (n === 'ShipmentDetailsView') {
          this.setView('single');
        }
      },
    },
  },

  beforeMount() {
    const view = this.$route.name;
    if (view === 'ShipmentListView') {
      this.setView('cluster');
    }
    if (view === 'ShipmentDetailsView') {
      this.setView('single');
    }
    if (view === 'ShipmentTrackView') {
      this.setView('single');
    }
  },

  methods: {
    setView(type) {
      this.view = type;
    },
  },
};
</script>
