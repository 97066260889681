<template>
  <ModalLayout title="Edit User">
    <Spinner v-if="isFetching" />
    <EditUserPermissionsModal
      v-else
      :user="user"
      :account="account"
      @close="emit('close')"
      @save="handleEditUserPermissions"
      :isSaving="isSavingPermissions"
    />
  </ModalLayout>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { EditUserPermissionsModal } from '@gpx-spa/ui';
import { getAccountUser, getUserDevices } from "@gpx-spa/queries";
import Spinner from "@gpx-spa/ui/src/elements/Spinner.vue";

const props = defineProps(['params']);
const emit = defineEmits(['close']);
const store = useStore();
const toast = useToast();

const isSavingPermissions = ref(false);
const accountId = computed(() => store.getters['auth/accountId']);
const account = computed(() => store.getters['auth/account']);
const activeModal = computed(() => store.getters['modal/active']);

const { data: user, isFetching } = getAccountUser(accountId.value, props.params.userId);

const handleEditUserPermissions = async (params) => {
  isSavingPermissions.value = true;
  console.log('saving user ', props.params.userId, 'with params', params)

  try {
    await store.dispatch('user/updateUserPermissions', { user_id: props.params.userId, params });
    toast.success('The user permissions were successfully updated');
    activeModal.value.params.onSuccess();
    emit('close');
    await store.dispatch('auth/fetchMe');
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.message);
    } else {
      console.log(e);
      toast.error('An error occurred. Please try again later.');
    }
  } finally {
    isSavingPermissions.value = false;
  }
};
</script>
